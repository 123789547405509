export const footerContent = [
    {
        index: 1,
        heading: "For providers",
        point: ["Become a Partner"],
    },
    {
        index: 2,
        heading: "Learn more",
        point: ["What is Medexe?", "Contact Us"],
    },
];

import './App.css';
import Index from './Router/Index';

function App() {
    return (
        <>
        <Index />
        </>
    );
}

export default App;
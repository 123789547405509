import axios from 'axios'

export const BASE_URL = 'https://medexe-api.azurewebsites.net/'

export const ENDINGS = {
    account: 'Account',
    detect: 'diseasedetect',
    question: 'question',
    getAnswers: 'question/getanswers',
    assessments: 'Assessments',
    doctors: 'doctorfinder',
    contactdata: 'ContactData',
    getAccount: 'Account/GetAccount',
    saveAccount: 'Account/SaveAccount'
}

export const createAPIEndpoint = endpoint => {

    let url = BASE_URL + 'api/' + endpoint + '/';
    return {
        fetch: () => axios.get(url),
        fetchById: id => axios.get(url + id),
        post: newRecord => axios.post(url, newRecord),
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord),
        delete: id => axios.delete(url + id),
    }
}
import { Box, styled } from "@mui/material";

const Center = styled(Box)((theme, ...props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

Center.displayName = "Center";

export default Center;

import React from 'react'
import { Button, Container, Grid, TextField, Typography } from '@mui/material'
import Center from './Center'
import useForm from '../hooks/useForm'
import { theme } from "../theme/theme";
import { ThemeProvider } from "@mui/material";
import { ui_colors } from "../theme/colors";
import "@fontsource/montserrat";
import { createAPIEndpoint, ENDINGS } from '../api'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CustomMailToButton from './CustomLayoutComponents/CustomMailToButton';

export default function ContactUs() {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [openError, setOpenError] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
        setOpenSuccess(false);
    };

    const getFreshModel = () => ({
        email: '',
        name: '',
        message: ''
    })

    const {
        values,
        errors,
        setErrors,
        handleInputChange
    } = useForm(getFreshModel);

    const validate = e => {
        let temp = {}
        temp.email = (/\S+@\S+\.\S+/).test(values.email) ? "" : "Email is not valid"
        temp.name = values.name !== "" ? "" : "Name is required"
        temp.message = values.message !== "" ? "" : "Message is required"
        setErrors(temp)
        return Object.values(temp).every(x => x === "")
    }

    const submitContactData = e => {
        e.preventDefault();
        if (validate())
            createAPIEndpoint(ENDINGS.contactdata)
                .post(values)
                .then(res => {
                    setOpenSuccess(true);
                })
                .catch(err => {
                    setOpenError(true);
                });
    }

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ mt: 20 }}>
                <Center>
                    <Grid container direction="column" maxWidth="75%">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h2" fontWeight={600} sx={{ color: ui_colors.primary, paddingBottom: '35px' }}>
                                        Have questions or comments?
                                    </Typography>
                                    <Typography variant="h5" sx={{ opacity: '0.4', paddingBottom: '35px' }}>
                                        Fill out the form below to get in touch with our team, or email
                                        us at <CustomMailToButton label="founders@medexe.org" mailto="mailto:founders@medexe.org" /> and we will return your email
                                        as soon as we can.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <Grid container sx={{ mt: 5 }}>
                                <Typography variant="h4" fontWeight={700} sx={{ paddingBottom: '35px' }}>
                                    Fill out the form below to get in touch with our team
                                </Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            required
                                            id="name"
                                            label="Name"
                                            name="name"
                                            margin="normal"
                                            value={values.name}
                                            onChange={handleInputChange}
                                            {...(errors.name && { error: true, helperText: errors.name })}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            required
                                            id="email"
                                            label="Email"
                                            name="email"
                                            margin="normal"
                                            value={values.email}
                                            onChange={handleInputChange}
                                            {...(errors.email && { error: true, helperText: errors.email })}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            required
                                            id="message"
                                            label="Message"
                                            name="message"
                                            margin="normal"
                                            multiline
                                            value={values.message}
                                            onChange={handleInputChange}
                                            {...(errors.message && { error: true, helperText: errors.message })}
                                        />
                                    </Grid>
                                    <Grid container justifyContent="right" alignItems="right" direction="row" spacing={2} style={{ marginTop: 20 }}>
                                        <Grid item >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={submitContactData}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Snackbar open={openError} autoHideDuration={5000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                            Your message could not be delivered, please try again.
                                        </Alert>
                                    </Snackbar >
                                    <Snackbar open={openSuccess} autoHideDuration={5000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                            Your message was sent to the Medexe team successfully!
                                        </Alert>
                                    </Snackbar >
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Center>
            </Container>
        </ThemeProvider>
    )
}
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Logo from "../../../assets/Logo.png";
import { Link } from 'react-router-dom'
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { CgFacebook } from "react-icons/cg";
import { GrLinkedinOption } from "react-icons/gr";
import { footerContent } from "./Footercontent";

const Footer = () => {

    const getLink = (index, pointIndex) => {
        switch (index) {
            case 1:
                switch (pointIndex) {
                    case 0:
                        return "/partners";
                    default:
                        return "/";
                }
            case 2:
                switch (pointIndex) {
                    case 0:
                        return "/about";
                    case 1:
                        return "/contact";
                    default:
                        return "/";
                }
            default:
                return "/";
        }
    };

    return (
        <Container style={{ marginTop: "4rem" }}>
            <Grid container spacing={2} >
                <Grid container item xs={12} md={12} lg={6}>
                    {footerContent.map((ele) => (
                        <>
                            <Grid item xs={4} key={ele.index}>
                                <p className="footer-link-heading" key={ele.index}>{ele.heading}</p>
                                {ele.point.map((el, index) => (
                                    <p className="footer-link" key={index}>
                                        <Link to={getLink(ele.index, index)} style={{ color: 'inherit', textDecoration: 'inherit' }}>{el}</Link>
                                    </p>
                                ))}
                            </Grid>

                        </>
                    ))}
                </Grid>
                <Grid item xs={12} lg={6}></Grid>
            </Grid>
            <div className="footer_position">
                <div>
                    <img src={Logo} alt='img' className="icon_size_footer" />
                </div>
                <div>
                    <p>Copyright &copy; 2023-2024 Medexe. All rights reserved.</p>
                </div>
                <div>
                    <ul className="icon_list">
                        <li className="icon_style">
                            {" "}
                            <BsTwitter />
                        </li>
                        <li className="icon_style">
                            <BsInstagram />
                        </li>
                        <li className="icon_style">
                            <CgFacebook />
                        </li>
                        <li className="icon_style">
                            <GrLinkedinOption />
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
    );
};

export default Footer;
import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import LandingImage from "../../assets/LandingImage.svg";
import { Content } from "./Content";
import Card from "@mui/material/Card";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import { useNavigate } from 'react-router-dom'
import { ui_colors } from "../../theme/colors";

const Home = () => {
    const navigate = useNavigate()

    const handlePartnerClick = () => {
        navigate("/partners");
    }

    const handleContactClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Container >
                <Box component="main" sx={{ mt: 9 }}>
                    <Toolbar />
                    <Typography className="heroText">
                        Healthcare without a hassle. Powered by AI.
                    </Typography>
                </Box>

                <Grid container className="contentdetailes-step1">
                    <Grid item lg={6} md={6} xs={12}>
                        <p className="paragraph_texts">
                            Transforming the way you experience healthcare - from symptoms to personalized solutions in a matter of minutes. Enjoy real-time diagnosis and diagnostics,
                            seamless care coordination, and tailored treatment plans, all from the comfort of your own device. Step into the future of healthcare with our AI-powered platform.
                        </p>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="langdingimage_block">
                        <img src={LandingImage} img="image" alt="Illustration of a hand holding a phone with text bubbles showing steps for entering symptoms and receiving a personalized care roadmap." className="landing_image" />
                    </Grid>
                </Grid>
                <div>
                    <p className="paragraph_texts"></p>
                    {/*<p className="paragraph_texts"></p>*/}
                    <p className="paragraph-two">A new way to get diagnosed</p>
                    <p className="paragraph-two-description">
                        We are reimagining diagnosis with artificial intelligence and precision medicine, and bringing it to your devices to untangle healthcare. 
                        <br/>You decide how to pay: pay per assessment or go unlimited with our monthly subscription.
                    </p>
                    <Grid container className="multicard-head-parent">
                        {Content.map((ele, index) => (
                            <>
                                <Grid lg={3} md={3} sm={3} xs={6} className="multicard-head">
                                    <>
                                        <Card className="multicard-img">
                                            <img
                                                src={ele.image}
                                                alt=""
                                                className="multicard_single-image"
                                            />
                                            <p className="multicard-info"></p>
                                            <Box className="text-info-multicard">
                                                <Typography className="multicard-info">
                                                    {ele.text}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </div>
                <div>
                    <div>
                        <p className="paragraph-two">How it works</p>
                        <p><br /></p>
                    </div>

                    <Grid container className="multicard-head-parent">
                        <Grid item lg={4} md={4} sm={12} xs={12} className="multicard-head">
                            <div>
                                <p className="paragraph_texts">
                                    <Filter1Icon sx={{ color: ui_colors.primary, fontSize: '40px' }} />
                                </p>
                                <h3 className="paragraph_heading">Get assessed.</h3>
                                <p className="paragraph_texts" >Take an assessment to see a personalized roadmap outlining next steps. Stop here for self-care or treatment, or use this as a launchpad for further diagnosis.</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} className="multicard-head">
                            <div>
                                <p className="paragraph_texts">
                                    <Filter2Icon sx={{ color: ui_colors.primary, fontSize: '40px' }} />
                                </p>
                                <h3 className="paragraph_heading">Narrow it down.</h3>
                                <p className="paragraph_texts" >Get same day diagnostic tests with same day results without a doctor visit at a reduced cost (regardless of insurance). Get an answer from our doctors within the hour, or share it with yours.</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} className="multicard-head">
                            <div>
                                <p className="paragraph_texts">

                                    <Filter3Icon sx={{ color: ui_colors.primary, fontSize: '40px' }} />
                                </p>

                                <h3 className="paragraph_heading">Get the help you need.</h3>
                                <p className="paragraph_texts" >Have our team review your assessment and guide you to the next steps without a visit (including prescriptions and specialist referrals), or schedule an appointment with one of our partners. </p>
                            </div>
                        </Grid>

                    </Grid>

                </div>
                <div>
                    <Container >
                        <Box className="step3-container" sx={{ mt: 9 }}>
                            <Grid container className="contentdetailes-step1">
                                <Grid item lg={6} md={6} xs={12}>
                                    <p className="paragraph_texts">
                                        <ConnectWithoutContactIcon sx={{ color: ui_colors.primary, fontSize: '70px' }} />
                                    </p>
                                    <h3 className="paragraph_heading" color={ui_colors.primary}>Contact Us</h3>

                                    <p className="paragraph_texts">
                                        Have any questions, concerns or feedback?
                                        <br />Reach out to us. We are all ears.
                                    </p>
                                    <div className="button-parent-couple">
                                        <button className="intial_button" onClick={handleContactClick}>Contact Us</button>
                                    </div>
                                </Grid>
                                <Typography className="heroText">
                                    <br />
                                </Typography>
                                <Grid item lg={6} md={6} xs={12}>
                                    <p className="paragraph_texts">
                                        <HandshakeIcon sx={{ color: ui_colors.primary, fontSize: '70px' }} />
                                    </p>
                                    <h3 className="paragraph_heading">Become a Partner</h3>

                                    <p className="paragraph_texts">
                                        We are looking to form partnerships with healthcare systems, providers, labs and academic institutions.
                                    </p>
                                    <div className="button-parent-couple">
                                        <button className="intial_button" onClick={handlePartnerClick}>For Partners</button>
                                    </div>
                                    <p className="paragraph_texts"></p>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            </Container>
        </>
    );
};

export default Home;

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppLogo from "../../../assets/Logo.png";
import { Collapse, Menu, MenuItem } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from 'react-router-dom'
const drawerWidth = 240;

function DrawerAppBar(props) {
    const navigate = useNavigate()
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [listment, setListmenu] = React.useState({
        1: false,
        2: false
    });
    const [anchorEl, setAnchorEl] = React.useState({ one: null, two: null });

    const handleClick = (event, pos) => {
        setAnchorEl({ ...anchorEl, [pos]: event.currentTarget });
    };
    const handleClose = (pos) => {
        setAnchorEl({ ...anchorEl, [pos]: null });
    };

    const handleMenuClickOne = (index) => {
        handleClose('one');
        if (index === 0) {
            navigate("/partners");
        }
    };

    const handleMenuClickTwo = (index) => {
        handleClose('two');
        if (index === 0) {
            navigate("/about");
        } else if (index === 1) {
            navigate("/contact");
        }
    };


    const drawer = (
        <Box sx={{ textAlign: "left" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
            </Typography>

            <List
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                    ></ListSubheader>
                }
            >
                <ListItemButton
                    onClick={() => setListmenu({ ...listment, 1: !listment[1] })}
                >
                    <ListItemText primary="For Partners" />
                    {listment[1] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={listment[1]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClickOne(0)}>
                            <ListItemText primary="Become a Partner"/>
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton
                    onClick={() => setListmenu({ ...listment, 2: !listment[2] })}
                >
                    <ListItemText primary="Learn  More" />
                    {listment[2] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={listment[2]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClickTwo(0)}>
                            <ListItemText primary="About" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClickTwo(1)}>
                            <ListItemText primary="Contact" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }} className="Appbar-parent">
            <CssBaseline />
            <AppBar component="nav" className="Appbar-background">
                <Toolbar sx={{ height: "6rem" }}>
                    <Typography
                        className="nav-logo-parent"
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: "block" } }}
                    >
                        <a href="https://www.medexe.org/"><img src={AppLogo} alt="medexelogo" className="navbar-Logo-full" /></a>
                    </Typography>
                    <Box
                        sx={{ display: { xs: "none", sm: "flex" } }}
                        className="test-position"
                    >
                        <Box>
                            <Button className="Appbar-link-button" onClick={(event) => handleClick(event, 'one')}>For Partners</Button>
                            <Menu
                                anchorEl={anchorEl.one}
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl.one)}
                                onClose={() => handleClose('one')}

                            >
                                {['Become a Partner'].map((setting, index) => (
                                    <MenuItem key={setting} onClick={() => handleMenuClickOne(index)}>
                                        <Typography className="Appbar-link-button" textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Box className="relative">
                            <Button className="Appbar-link-button" onClick={(event) => handleClick(event, 'two')}>Learn More</Button>
                            <Menu
                                anchorEl={anchorEl.two}
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl.two)}
                                onClose={() => handleClose('two')}

                            >
                                {['About', 'Contact'].map((setting, index) => (
                                    <MenuItem key={setting} onClick={() => handleMenuClickTwo(index)}>
                                        <Typography className="Appbar-link-button" textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box component="nav" className="bargure-menu ">
                <Drawer
                    anchor="right"
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

DrawerAppBar.propTypes = {
    window: PropTypes.func,
};

export default DrawerAppBar;

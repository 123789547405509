import { Container, Grid, Typography } from '@mui/material';
import { React } from 'react';
import Center from './Center';
import { theme } from "../theme/theme";
import { ThemeProvider } from "@mui/material";
import { ui_colors } from "../theme/colors";
import "../css/faq.css";

export default function About() {
    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ mt: 20 }}>
                <Center>
                    <Grid container direction="column" maxWidth="80vw">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container>
                                <Typography variant="h2" fontWeight={600} sx={{ color: ui_colors.primary, paddingBottom: '35px' }}>
                                    About Us
                                </Typography>
                                <Typography variant="h4" fontWeight={300} sx={{ paddingBottom: '30px' }}>
                                    We are leveraging AI and a precision medicine approach to bring diagnosis and diagnostics to your fingertips.
                                </Typography>
                                <Typography variant="h4" fontWeight={300} sx={{ paddingBottom: '30px' }}>
                                    Our mission is to reshape healthcare by reimagining the differential diagnosis process and improving the patient experience with AI.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Center>
            </Container>
        </ThemeProvider>
    )
}
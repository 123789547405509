import img3 from '../../assets/img3.svg'
import img4 from '../../assets/img4.svg'
import img2 from '../../assets/img2.svg'
import img1 from '../../assets/img1.svg'
export const Content = [
    {
        image: img1,
        text: "AI-powered, personalized condition assessment and medical chatbot"
    },
    {
        image: img2,
        text: "Same-day diagnostic tests at a reduced cost without a visit and AI-assisted results"
    },
    {
        image: img3,
        text: "Reviews for an expert opinion, prescriptions, or referrals within the hour"
    },
    {
        image: img4,
        text: "Seamless handoff to providers that are equipped with key health insights"
    }
]

export const step3Content =
    [
        {
            heading: "Become a partner",
            info: "We are looking to form partnerships with healthcare systems, providers, labs and academic institutions. ",
            buttontext: "Partnerships"
        },
        {
            heading: "Get in touch",
            info: "Questions, concerns or feedback? Reach out to us. We are all ears.",
            buttontext: "Contact Us"
        },


    ]

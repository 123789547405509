import { alpha, createTheme } from "@mui/material";
import { ui_colors } from "./colors";

export const paperShadow = {
    boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
};

export const hide_scroll = {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": { display: "none" },
};

export const custom_button = (color) => ({
    bgcolor: color,
    color: ui_colors.primary,
    fontWeight: 600,
    borderRadius: 2,
    mt: 2,
    ":hover": {
        bgcolor: color,
        color: ui_colors.primary,
        fontWeight: 600,
        borderRadius: 2,
        mt: 2,
    },
});

export const lab_button = (color) => ({
    margin: 5,
    bgcolor: ui_colors.bgcolor,
    color: ui_colors.primary,
    border: 1,
    bordercolor: ui_colors.primary,
    borderRadius: 2,
});

export const coupon_button = (color) => ({
    bgcolor: ui_colors.bgcolor,
    color: ui_colors.primary,
    border: 1,
    bordercolor: ui_colors.primary,
    borderRadius: 2,
});

export const theme = createTheme({
    palette: {
        primary: {
            main: ui_colors.primary,
        },
    },
    typography: {
        fontFamily: `'Montserrat', sans-serif`,
        h1: {
            fontSize: 50,
            fontWeight: 700,
        },
        h2: {
            fontSize: 45,
            fontWeight: 700,
        },
        h3: {
            fontSize: 28,
            fontWeight: 700,
        },
        h4: {
            fontSize: 23,
            fontWeight: 700,
        },
        h5: {
            fontSize: 18,
            fontWeight: 700,
        },
        h6: {
            fontSize: 12,
        },
        body1: {
            fontSize: 14,
            color: ui_colors.blue_gray,
            fontWeight: 500,
        },
        body2: {
            fontSize: 16,
            color: ui_colors.blue_gray,
            fontWeight: 500,
        },
    },

    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: ui_colors.black,
                },
            },
        },

        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: alpha("#000", 0.7),
                },
            },
        },

        MuiButton: {
            defaultProps: {
                disableElevation: true,
                variant: "contained",
            },
            styleOverrides: {
                sizeSmall: {
                    fontSize: 12,
                    height: 32,
                },
                startIcon: {
                    "svg:nth-of-type(1)": { fontSize: 15 },
                },
                root: {
                    borderRadius: 999,
                    height: "40px",
                    fontSize: 14,
                    textTransform: "none",
                    position: "relative",
                    whiteSpace: "nowrap",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                sizeSmall: {
                    input: {
                        height: "14px",
                    },
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    "&.contained": {
                        background: ui_colors.primary,
                        color: "#fff",
                        ":hover": {
                            background: alpha(ui_colors.primary, 0.7),
                            color: "#fff",
                        },
                    },
                    ".MuiTouchRipple-ripple": {
                        span: {
                            borderRadius: "4px",
                        },
                    },
                },
            },
        },

        MuiLoadingButton: {
            defaultProps: {
                disableElevation: true,
                variant: "contained",
            },
        },

        MuiSkeleton: {
            defaultProps: { animation: "wave", variant: "rectangular" },
            styleOverrides: {
                rectangular: { borderRadius: 4 },
                root: {
                    background: "#a8b1c750",
                },
            },
        },

        MuiTabs: {
            styleOverrides: {
                root: {
                    position: "relative",
                    height: 35,
                    minHeight: 0,
                    ".MuiTabs-scroller": {
                        height: "100%",
                    },
                    button: {
                        zIndex: 2,
                        minHeight: 0,
                        borderRadius: "999px",
                        height: "100%",
                        color: ui_colors.lightText,
                        "&.MuiTab-root": {
                            padding: "0 1.7em",
                        },
                        "&.Mui-selected": {
                            color: "#fff",
                        },
                    },
                    ".MuiTabs-flexContainer": {
                        justifyContent: "center",
                        gap: 3,
                        maxWidth: "fit-content",
                        margin: "auto",
                        background: "#fff",
                        borderRadius: "999px",
                        overflow: "hidden",
                        height: "100%",
                    },

                    ".MuiTab-root": { padding: 1 },
                    ".MuiTabs-indicator": {
                        height: "100%",
                        background: ui_colors.primary,
                        borderRadius: "999px",
                        zIndex: 1,
                    },
                },
            },
        },

        MuiTextField: {
            defaultProps: {
                variant: "filled",
                size: "medium",
            },
            styleOverrides: {
                root: {
                    ".MuiFilledInput-root": { paddingRight: 0 },

                    ".MuiInputBase-root::before,.MuiInputBase-root::after": {
                        content: "none",
                    },
                    ".MuiInputLabel-root": {
                        fontSize: 14,
                        lineHeight: "1.8",
                        overflow: "visible",
                    },

                    ".MuiInputBase-root": {
                        borderRadius: 6,
                        boxShadow: "0 0 0 1px #dddddd",
                        overflow: "hidden",
                        background: "#fff",
                        input: {
                            height: "16PX",
                            paddingTop: 24,
                            paddingBottom: 6,
                        },
                    },
                    ".MuiInputBase-root.Mui-error": {
                        borderRadius: 6,
                        boxShadow: "0 0 0 1px " + ui_colors.error,
                        background: alpha(ui_colors.error, 0.1),
                    },
                    ".MuiInputBase-input": {
                        fontSize: 14,
                    },
                    ".MuiInputBase-root.Mui-focused:not(.Mui-error)": {
                        boxShadow: " 0 0 0 1px " + ui_colors.primary,
                    },
                    ".MuiFormLabel-root": {
                        transform: "translate(12px, 11px) scale(1)",
                        "&.MuiInputLabel-shrink": {
                            transform: "translate(12px, 4px) scale(0.8)",
                        },
                    },
                },
            },
        },
    },
});

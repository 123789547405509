import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import Footer from "../components/Layout/Footer/Footer";
import Navbar from "../components/Layout/Navbar/Navbar";
import About from '../components/About';
import ContactUs from '../components/ContactUs';
import BecomePartner from "../components/BecomePartner";
import TermsAndConditions from "../components/TermsAndConditions";
import PrivacyPolicy from "../components/PrivacyPolicy";

const Index = () => {
    return (
        <>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/partners" element={<BecomePartner />} />
                    <Route path="/termsandconditions" element={<TermsAndConditions />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
};

export default Index;
